import {
  Box,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { userAPI } from "../api/TimestampAPI";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { isAdmin } from "../Helper";
import { THEME } from "../Settings";
import { TenantConfigContext } from "../context/tenantConfigContext";

const NavList = styled(List)(({ theme }) => ({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: theme.palette.background.default,
    "&, & .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    backgroundColor: theme.palette.background.default,
    "&, & .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
  },
}));

const MenuActionButton = styled(ListItemButton)<ListItemButtonProps>((props) => ({
  borderRadius: "10px",
  textTransform: "capitalize",
  width: "100%",
  margin: 0,
  backgroundColor: props.theme.palette.primary.main,
  // padding: "10px 15px",
}));

const MenuActionIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "40px",
}));

const CreateProjectButton = styled(MenuActionButton)(({ theme }) => ({
  fontWeight: 600,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));


const Sidebar = ({
  isNavbarActive,
  setCreateProject,
}) => {
  const tenantConfig = useContext(TenantConfigContext);
  const { t } = useTranslation();
  const location = useLocation();
  const { tokenData } = useContext<IAuthContext>(AuthContext);

  const [userStorage, setUserStorage] = useState(0);
  const vaultSize = 1000000000; // 1GB
  const usagePercentage = (userStorage / vaultSize) * 100;
  const usageInMb = userStorage / 1000000;

  useEffect(() => {
    (async () => {
      const data = await userAPI.getStorage();
      setUserStorage(data?.storage_usage);
    })();
  }, []);

  const renderVaultUsage = (usageInMb, usagePercentage) => {
    let usageInMbText = `${usageInMb.toFixed(2)} MB`;
    let usagePercentageText = `${usagePercentage.toFixed(2)}%`;
    const text = <Trans i18nKey="usedDataWithLimit" components={{ strong: <strong /> }} values={{ dataMb: usageInMbText, dataPercent: usagePercentageText }} />;
    let showProgress = true;
    if (tenantConfig?.show_storage !== true) {
      return <></>
    }


    return (
      <Box
        sx={{
          backgroundColor: "#F4F7FE9E",
          padding: 2,
          marginTop: "auto",
          marginBottom: 2,
          borderRadius: 2,
        }}
      >
        <Typography sx={{ fontSize: "13px", fontWeight: "600" }} variant="subtitle2">
          {t("vault storage")}
        </Typography>
        <Typography sx={{ pt: 1, pb: 1, fontSize: "13px" }} variant="body2" component="p">
          {text}
        </Typography>
        {showProgress && (
          <LinearProgress
            variant="determinate"
            value={usagePercentage}
            sx={{ height: "6px", borderRadius: "5px" }}
          />
        )}
      </Box>
    );
  }

  return (
    <Box
      component="nav"
      sx={{
        display: "flex",
        width: 240,
        maxWidth: 240,
        flexShrink: 0,
        pt: 9,
        flexDirection: "column",
        paddingX: 1,
        minHeight: "100vh",
      }}
      className={`navbar ${isNavbarActive ? "active" : null}`}
    >
      <List>
        <ListItem disableGutters>
          <CreateProjectButton
            disableRipple
            onClick={() => setCreateProject(true)}
          >
            <MenuActionIcon>
              <CreateNewFolderIcon sx={{ color: "white" }} />
            </MenuActionIcon>
            <ListItemText disableTypography style={{ textTransform: "none" }} primary={t("create project")} />
          </CreateProjectButton>
        </ListItem>
      </List>

      <Divider />

      <NavList>
        <ListItemButton
          disableRipple
          component={Link}
          to="/projects"
          selected={location.pathname === "/projects"}
        >
          <MenuActionIcon>
            <DashboardCustomizeRoundedIcon />
          </MenuActionIcon>
          <ListItemText primary={t("my projects")} />
        </ListItemButton>
        <ListItemButton
          disableRipple
          component={Link}
          to="/links"
          selected={location.pathname === "/links"}
        >
          <MenuActionIcon>
            <LinkRoundedIcon />
          </MenuActionIcon>
          <ListItemText primary={t("shared_links")} />
        </ListItemButton>
        {isAdmin(tokenData) ? (
          <ListItemButton
            disableRipple
            component={Link}
            to="/admin"
            selected={location.pathname === "/admin"}
          >
            <MenuActionIcon>
              <AdminPanelSettingsRoundedIcon />
            </MenuActionIcon>
            <ListItemText primary={`${t("admin")}`} />
          </ListItemButton>
        ) : null}
      </NavList>
      {renderVaultUsage(usageInMb, usagePercentage)}
    </Box>
  );
};

export default Sidebar;
