import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

import ProjectDetail from "./pages/ProjectDetail";
import MyProjects from "./pages/MyProjects";
import VersionedFile from "./pages/VersionedFile";

import {
  AuthContext,
  IAuthContext,
} from "react-oauth2-code-pkce";
import ExternalShare from "./pages/ExternalShare";
import { Layout } from "./components/Layout";
import Stats from "./pages/admin/Dashboard";
import SharingLinks from "./pages/SharingLinks";
import { TenantConfigContext } from "./context/tenantConfigContext";
import { useGetTenantConfig } from "./api/tenant/getTenantConfig";

function LoginRedirect() {
  const { login } = useContext<IAuthContext>(AuthContext);
  const [seconds, setSeconds] = useState(1);

  useEffect(() => {
    // Exit early when we reach 0
    if (seconds === 0) {
      login();
      return;
    }

    // Save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setSeconds(seconds => seconds - 1);
    }, 1000);

    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // Add seconds and login to the dependency array to re-run the effect
    // when we update the state or the login function changes.
  }, [seconds, login]);
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <CircularProgress />
    </Box>
  );
}

const RedirectToHome: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  });

  return <></>;
}

const App: React.FC = () => {
  var {
    token,
    error,
    login
  } = useContext<IAuthContext>(AuthContext);

  const { data: tenantConfig } = useGetTenantConfig({});

  // Hack to get around requiring a login for the share page
  // Also hacks an issue with AuthProvider which sometimes does not redirect to the login page
  // https://github.com/soofstad/react-oauth2-pkce/discussions/89
  const path = window.location.pathname;
  if (error && !path.startsWith("/share")) {
    login()
  }
  return (
    <>
      <TenantConfigContext.Provider value={tenantConfig}>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route path="/share/:shareid" element={<ExternalShare />} />

            {/* Private Routes */}
            {token && (
              <>
                <Route path="/" element={<Layout />}>
                  <Route index element={<MyProjects />} />
                  <Route path="/login/check" element={<RedirectToHome />} />
                  <Route
                    path="/projects/:projectId/:creationId/:versionNr"
                    element={<VersionedFile />}
                  />
                  <Route
                    path="/projects/:projectId/:creationId"
                    element={<VersionedFile />}
                  />
                  <Route path="/projects/:projectId" element={<ProjectDetail />} />
                  <Route path="/projects/" element={<MyProjects />} />
                  <Route path="/links/" element={<SharingLinks />} />
                  <Route
                    path="/admin"
                    element={<Stats />} />
                </Route>
                <Route path="/share/:shareid" element={<ExternalShare />} />
              </>
            )}
            {/* Default Redirect to Login Route */}
            <Route path="/" element={<LoginRedirect />} />
          </Routes>
        </Router>
      </TenantConfigContext.Provider >
    </>
  );
}


export default App;
