import React, { useState, useEffect } from "react";
import { Box, Button, FormControlLabel, FormGroup, IconButton, InputAdornment, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Tooltip, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TableLink from './Table/Link';
import CreationTypeIcon from './CreationTypeIcon';
import { getUserAvatarWithText } from '../Helper';
import moment from 'moment';
import Pagination from './Pagination';
import { useGetCreations } from "../api/creations/getCreations";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmDeleteModal from "../modals/ConfirmDeleteDialog";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDeleteCreationVersion } from "../api/creations/deleteCreationVersion";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SortableHeading from "./Table/SortableHeading";
import { grey } from "@mui/material/colors";
import ErrorAlert from "./Snackbar/ErrorAlert";

type CreationTableProps = {
    projectId?: string;
    includeVersions?: boolean;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButton-root': {
        padding: 4,
    },
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
        textTransform: 'capitalize',
    },
}));

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    "&.Mui-selected": {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            color: "#fff",
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const NestedRowCell = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
}));


const CreationTable: React.FC<CreationTableProps> = ({ projectId, includeVersions = false }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [selectedCreationVersion, setSelectedCreationVersion] = React.useState({});
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isDeleteErrorOpen, setDeleteErrorOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [searchValue, setSearchValue] = useState('');
    const [currentSearchValue, setCurrentSearchValue] = useState('');
    const [showAllVersions, setShowAllVersions] = useState(localStorage.getItem("projectDetailCreationShowAllVersions") === "true" || false);
    const [displayType, setDisplayType] = useState(localStorage.getItem("projectDetailCreationdisplayType") || "creations");
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const perPage = 10;

    // const expandAll = displayType === "creationVersions";
    const expandAll = showAllVersions

    const filters = projectId ? { project_id: projectId } : null;
    const { data: creationsResp } = useGetCreations({ page, perPage, filters, sortBy: sortConfig.key, sortDir: sortConfig.direction, search: currentSearchValue, includeVersions });
    const { mutate: deleteCreationVersion } = useDeleteCreationVersion(() => setDeleteErrorOpen(true));

    const creations = creationsResp?.results;

    useEffect(() => {
        if (creations) {
            setPageCount(Math.ceil(creationsResp.count / perPage))
        }
    }, [creations]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchValue === currentSearchValue) {
                return
            } else if (searchValue === '') {
                setCurrentSearchValue('')
            } else if (searchValue.length < 3) {
                return
            }
            console.log(searchValue)
            setCurrentSearchValue(searchValue)
        }, 1500)

        return () => clearTimeout(delayDebounceFn)
    }, [searchValue])

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleClickDelete = (creationVersion) => {
        setSelectedCreationVersion(creationVersion);
        setDeleteModalOpen(true);
    }

    const handleDelete = (creationVersion) => {
        deleteCreationVersion(creationVersion);
        setDeleteModalOpen(false);
        setSelectedCreationVersion({})
    };

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // const handleDisplayTypeChange = (event, newDisplayType) => {
    //     setDisplayType(newDisplayType);
    //     localStorage.setItem("projectDetailCreationdisplayType", newDisplayType);
    // }

    const handleShowAllVersionsChange = (event) => {
        setShowAllVersions(event.target.checked);
        localStorage.setItem("projectDetailCreationShowAllVersions", event.target.checked);
    }

    const renderSearchForm = () => {
        return (
            <Box sx={{ width: "50%" }}>
                <TextField
                    fullWidth
                    focused
                    size={"small"}
                    id="outlined-basic"
                    variant="outlined"
                    value={searchValue}
                    placeholder={t("type_to_search")}
                    onChange={(e) => setSearchValue(e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <SearchRoundedIcon />
                        </InputAdornment>
                    }}
                />
            </Box>
        )
    }

    const renderToggle = () => {
        return (
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showAllVersions}
                            onChange={handleShowAllVersionsChange}
                        />
                    }
                    label={t("show_all_versions")}
                    labelPlacement="start"
                />
            </FormGroup>
        )
    }

    const NestedRow = ({ creation, version, index }) => {
        // Use NestedRowCell if index != last index, otherwise use TableCell
        const isLast = index === creation.versions.length - 1;
        const Cell = isLast ? TableCell : NestedRowCell;

        return (
            <TableRow>
                <Cell>
                    <TableLink href={`/projects/${creation?.project_id}/${creation?.id}/${version.version}`} sx={{ marginLeft: '56px' }}>
                        {version?.name}
                    </TableLink>
                </Cell>
                <Cell>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                    }}>
                        <CreationTypeIcon creationType={creation?.creation_type} />
                        {t(creation.creation_type)}
                    </Box>

                </Cell>
                <Cell>
                    {getUserAvatarWithText(version?.created_by)}
                </Cell>
                <Cell>
                    {moment(version.date_created).format("LLL")}
                </Cell>
                <Cell align="center">
                    {version.version}
                </Cell>
                <Cell>
                    <Tooltip title={t('delete')}>
                        <IconButton onClick={() => handleClickDelete(version)}>
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Cell>
            </TableRow>
        )
    }


    const Row = ({ creation }) => {
        const disabledArrow = creation?.versions.length === 1;
        const [expanded, setExpanded] = useState(expandAll && !disabledArrow)

        const nestedBorder = {
            borderTopColor: grey[50],
            borderBottomColor: grey[50]
        }
        return (
            <>
                <ErrorAlert
                    open={isDeleteErrorOpen}
                    onClose={() => setDeleteErrorOpen(false)}
                    message={t("delete_creation_version_error")}
                />
                <TableRow sx={{
                    backgroundColor: expanded && !disabledArrow ? theme.palette.action.hover : "inherit"
                }}>
                    <TableCell>
                        <Box sx={{
                            display: "flex", alignItems: "center", gap: 1,
                        }}>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setExpanded(!expanded)}
                                disabled={disabledArrow}
                            >
                                {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
                            </IconButton>
                            <TableLink href={`/projects/${creation.project_id}/${creation.id}`}>
                                {creation?.current_version?.name}
                            </TableLink>
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Box sx={{
                            display: "flex", alignItems: "center", gap: 1,
                        }}>
                            <CreationTypeIcon creationType={creation?.creation_type} />
                            {t(creation.creation_type)}
                        </Box>
                    </TableCell>
                    <TableCell>
                        {getUserAvatarWithText(creation?.created_by)}
                    </TableCell>
                    <TableCell>
                        {moment(creation.current_version.date_created).format("LLL")}
                    </TableCell>
                    <TableCell align="center">
                        {creation.current_version.version}
                    </TableCell>
                    <TableCell>
                        <Tooltip title={t('delete')}>
                            <IconButton onClick={() => handleClickDelete(creation.current_version)}>
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
                {/* Nested Version Rows  */}
                {expanded && creation?.versions.map((version, index) => (
                    //  only show if current_version is not the same as version
                    version.version !== creation.current_version.version &&
                    <NestedRow key={index} creation={creation} version={version} index={index} />
                ))}
            </>
        );
    }

    return (
        <>
            <ConfirmDeleteModal
                open={isDeleteModalOpen}
                message={t('delete_creation_version_message')}
                onConfirm={() => handleDelete(selectedCreationVersion)}
                onClose={() => setDeleteModalOpen(false)}
            />
            <Stack direction="row" justifyContent="space-between" sx={{ my: 3, mr: 1 }}>
                {renderSearchForm()}
                {renderToggle()}
            </Stack>

            <TableContainer>
                <Table sx={{}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <SortableHeading keyProp="name" value={t("filename")} sortConfig={sortConfig as any} requestSort={requestSort} />
                            <TableCell>{t("file type")}</TableCell>
                            <TableCell>{t("uploaded by")}</TableCell>
                            <SortableHeading keyProp="version_date_created" value={t("upload date")} sortConfig={sortConfig as any} requestSort={requestSort} />
                            <TableCell>{t("version")}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {creations?.map((creation, index) => (
                            <Row key={index} creation={creation} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination count={pageCount} onChange={handlePageChange} />
        </>
    )
}

export default CreationTable
