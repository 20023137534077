import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Dropzone from "react-dropzone";
import { useParams, useNavigate } from "react-router-dom";
import UploadCreationVersion from "../modals/CreateCreationOrVersion";
import FolderIcon from "@mui/icons-material/Folder";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGetProject } from "../api/projects/getProject";
import { InboxIcon } from "../components/Icons/icons";
import CreationTable from "../components/CreationTable";

const ProjectDetail = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [droppedFiles, setFiles] = React.useState([]);
  const [selectedFile, setSelectFile] = React.useState(null);

  const theme = useTheme();

  const onDrop = React.useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    if (acceptedFiles.length > 0) {
      setSelectFile(acceptedFiles[0]);
    }
  }, []);
  const clearFiles = () => setFiles([]);

  const { data: project, error } = useGetProject({ projectId });
  if (error?.message === "Request failed with status code 403") {
    // forbidden
    navigate("/projects");
  }

  const handleCloseCreationModal = () => {
    setSelectFile(null);
    setFiles([]);
  };

  const handleCreateCreation = () => {
    setSelectFile(null);
    setFiles([]);
  };

  const { t } = useTranslation();
  return (
    <Box sx={{ width: "100%" }}>
      <UploadCreationVersion
        open={selectedFile != null}
        onClose={handleCloseCreationModal}
        onSubmit={handleCreateCreation}
        selectedFile={selectedFile}
        projectId={projectId}
        creationId={null}
      />

      <Box sx={{ mb: 2, mt: 1, display: "flex", gap: "10px", alignItems: "center" }}>
        <IconButton onClick={() => navigate("/projects")}>
          <ArrowBackIcon />
        </IconButton>
        <>
          <Typography
            variant="h6"
            sx={{
              textTransform: "capitalize",
              color: "rgba(172, 172, 185, 1)",
              fontWeight: "400",
            }}
          >
            {t('my projects')} /
          </Typography>
          <Typography
            variant="h6"
            sx={{
              textTransform: "capitalize",
              fontWeight: "600",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <FolderIcon />
              {project?.name}
            </Box>
          </Typography>
        </>
      </Box>

      <Typography variant="subtitle1" color={theme.palette.text.secondary}>
        {t('project_detail_description')}
      </Typography>

      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              style={{
                border: `2px dotted ${theme.uploadBoxBorderColor}`,
                background: theme.uploadBoxBg,
                padding: "20px",
                textAlign: "center",
                borderRadius: "5px",
                margin: "30px 0px 10px 0px",
              }}
            >
              <input {...getInputProps()} />
              <InboxIcon color="primary" style={{ fill: theme.palette.primary.main }} sx={{ width: "35px", height: "35px", cursor: "pointer" }} />
              <Typography
                paragraph
                sx={{ marginBottom: "0", marginTop: "20px" }}
              >
                {t("Click or drag file")}
              </Typography>
              <Typography
                sx={{ color: " rgba(0, 0, 0, 0.45)", marginBottom: "0" }}
                paragraph
              >
                {t("file formats")}
              </Typography>
              <ul>
                {droppedFiles.map((file, index) => (
                  <li key={index}>
                    {file?.name} - {file.size} bytes
                  </li>
                ))}
              </ul>
            </div>
            {droppedFiles.length > 0 && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={clearFiles}
                >
                  Clear Files
                </Button>
              </div>
            )}
          </section>
        )}
      </Dropzone>

      {project?.description && (
        <Box sx={{ mb: 2 }}>
          <List sx={{ display: "flex", flexDirection: "row" }}>
            <ListItem sx={{ pl: 0 }}>
              <ListItemText primaryTypographyProps={{ variant: "h6" }} primary={t("description")} secondary={project.description} />
            </ListItem>
          </List>
        </Box>
      )}

      <Typography variant="h6" sx={{ mb: 1 }}>{t("creations")}</Typography>

      <CreationTable projectId={projectId} includeVersions={true} />

    </Box >
  );
};

export default ProjectDetail;
