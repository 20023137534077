import {
  AppBar,
  Box,
  Link,
  Stack,
  Toolbar,
} from "@mui/material";
import ProfileDropdown from "./ProfileDropdown";
import ProfileSelect from "./ProfileSelect";
import { useContext } from "react";
import { TenantConfigContext } from "../context/tenantConfigContext";
import { useTranslation } from "react-i18next";

export const TopAppBar = ({ isNavbarActive, handleNavbarClick, theme }) => {
  const tenantConfig = useContext(TenantConfigContext);
  const { t } = useTranslation();
  return (
    <>
      <Box className={`overlay ${isNavbarActive ? "active" : ""}`}></Box>
      <AppBar
        sx={{
          // zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "background.paper",
          boxShadow: "0px 4px 10px -1px rgba(227, 236, 251, 1)",
          padding: "5px 0px",
          color: "rgba(92, 92, 111, 1)",
          maxWidth: "2214px",
          // stick to left
          position: "fixed",
          left: 0,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* HAMBURGER / CROSS */}
          <Box className="hamburger">
            <button
              onClick={handleNavbarClick}
              className={isNavbarActive ? "active" : null}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </Box>

          {/* LOGO */}
          <Stack direction="row" gap={"50px"}>
            <a href={tenantConfig?.logo_url || '#'}>
              <Box sx={{ width: "12%", display: { sm: "block", xs: "none" } }}>
                <img src={theme?.logo} style={{ maxHeight: "50px", maxWidth: "160px" }} alt="" />
              </Box>
            </a>
            {tenantConfig?.header_link && (
              <Link href={tenantConfig?.header_link?.url} color={"inherit"} underline={"hover"}>{t('go_back_to')}{' '}{tenantConfig?.header_link?.title}</Link>
            )}
          </Stack>

          {/* SEARCH BAR - Check GIT History for implementation */}

          {/* User Profile Dropdown */}
          <Stack direction="row" spacing={2} alignItems={"center"}>
            <ProfileSelect />
            <ProfileDropdown />
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};
