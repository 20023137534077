import { List } from "@mui/material";
import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    defaultTheme: Palette["primary"];
  }

  interface PaletteOptions {
    defaultTheme?: PaletteOptions["primary"];
  }

  interface Theme {
    logo: string;
    sharebg: string;
    uploadBoxBg: string;
    uploadBoxBorderColor: string;
  }

  interface ThemeOptions {
    logo?: string;
    sharebg?: string;
    uploadBoxBg: string;
    uploadBoxBorderColor: string;
  }
}

const bumaStemraThemePalette = createTheme({
  logo: "/assets/images/logo.png",
  sharebg: "rgba(17, 17, 187, 1)",
  uploadBoxBg: "rgba(244, 247, 254, 1)",
  uploadBoxBorderColor: "rgba(115, 186, 252, 1)",
  typography: {
    fontFamily: "Inter",
    fontSize: 14,
  },
  palette: {
    mode: "light",
    primary: {
      main: "rgba(17, 17, 187, 1)",

    },
    secondary: {
      main: "rgba(222, 237, 251, 1)",
    },
    tonalOffset: {
      light: 0.2,
      dark: 0.1,
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    text: {
      primary: "rgba(92, 92, 111, 1)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: 5,
          // padding: "10px 15px",
          textTransform: "none",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedSecondary: {
          color: "rgba(17, 17, 187, 1)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            }
          }
        }
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        focused: true,
        InputLabelProps: {
          shrink: true,
          style: { color: "rgba(92, 92, 111, 1)" },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
        focused: true
      },
    },
    MuiSelect: {
      defaultProps: {

      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "rgba(17, 17, 187, 1)",
          fontWeight: 600,
        },
      },
    }
  }
});

const bumaStemraTheme = {
  theme: bumaStemraThemePalette,
  logo: "/assets/images/logo.png",
  sharebg: "rgba(17, 17, 187, 1)",
  name: "BumaStemra",
  defaultLanguage: "nl",
  avatarColor: "#11AA99"
};

const cpdThemePalette = createTheme({
  logo: "/assets/svg/logo.svg",
  sharebg: "/assets/images/alternate_background.png",
  uploadBoxBg: "rgba(151, 71, 255, 0.05)",
  uploadBoxBorderColor: "rgba(151, 71, 255, 0.3)",
  typography: {
    fontFamily: "Manrope",
  },
  palette: {
    mode: "light",
    primary: {
      main: "rgba(108, 46, 211, 1)",
    },
    secondary: {
      main: "rgba(243, 245, 255, 1)",
    },
    tonalOffset: {
      light: 0.2,
      dark: 0.1,
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    text: {
      primary: "rgba(15,23,42, 0.9)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: 10,
          // padding: "10px 15px",
          textTransform: "capitalize",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedSecondary: {
          color: "rgba(108, 46, 211, 1)",
        },
      },
    },
  },
});

const cpdTheme = {
  theme: cpdThemePalette,
  logo: "/assets/svg/logo.svg",
  sharebg: "/assets/images/alternate_background.png",
  name: "Copyright Delta",
  defaultLanguage: "en",
};

const kodaThemePalette = createTheme({
  logo: "/assets/images/koda-logo.png",
  sharebg: "/assets/images/kodaExternalShare.png",
  uploadBoxBg: "rgb(15, 37, 51, 0.05)",
  uploadBoxBorderColor: "rgb(15, 37, 51, 0.3)",
  typography: {
    fontFamily: "PT Sans",
    fontSize: 14,
  },
  palette: {
    mode: "light",
    primary: {
      main: "rgb(15, 37, 51)",

    },
    secondary: {
      main: "rgba(222, 237, 251, 1)",
    },
    tonalOffset: {
      light: 0.2,
      dark: 0.1,
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    text: {
      primary: "rgba(56, 56, 56, 1)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: 5,
          // padding: "10px 15px",
          textTransform: "none",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedSecondary: {
          color: "rgb(15, 37, 51)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            }
          }
        }
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        focused: true,
        InputLabelProps: {
          shrink: true,
          style: { color: "rgba(56, 56, 56, 1)" },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
        focused: true
      },
    },
    MuiSelect: {
      defaultProps: {

      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "rgb(15, 37, 51)",
          fontWeight: 600,
        },
      },
    }
  }
});

const kodaTheme = {
  theme: kodaThemePalette,
  logo: "/assets/images/koda-logo.png",
  sharebg: "/assets/images/kodaExternalShare.png",
  name: "koda",
  defaultLanguage: "en",
};

const demoThemePalette = createTheme({
  logo: "/assets/images/demo-logo.png",
  sharebg: "/assets/images/externalShare.png",
  uploadBoxBg: "rgba(244, 247, 254, 1)",
  uploadBoxBorderColor: "rgba(115, 186, 252, 1)",
  typography: {
    fontFamily: "Inter",
    fontSize: 14,
  },
  palette: {
    mode: "light",
    primary: {
      main: "rgba(17, 17, 187, 1)",

    },
    secondary: {
      main: "rgba(222, 237, 251, 1)",
    },
    tonalOffset: {
      light: 0.2,
      dark: 0.1,
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    text: {
      primary: "rgba(92, 92, 111, 1)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: 5,
          // padding: "10px 15px",
          textTransform: "capitalize",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedSecondary: {
          color: "rgba(17, 17, 187, 1)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            }
          }
        }
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        focused: true,
        InputLabelProps: {
          shrink: true,
          style: { color: "rgba(92, 92, 111, 1)" },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
        focused: true
      },
    },
    MuiSelect: {
      defaultProps: {

      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "rgba(17, 17, 187, 1)",
          fontWeight: 600,
        },
      },
    }
  }
});

const demoTheme = {
  theme: demoThemePalette,
  logo: "/assets/images/demo-logo.png",
  sharebg: "/assets/images/externalShare.png",
  name: "Demo",
  defaultLanguage: "en"
};


export const themes = {
  BUMASTEMRA: bumaStemraTheme,
  COPYRIGHTDELTA: cpdTheme,
  DEFAULT: bumaStemraTheme,
  KODA: kodaTheme,
  DEMO: demoTheme
};
