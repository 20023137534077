import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { themes } from "../theme/themes";
import { CRUD } from "../Constants";
import { TopAppBar } from "./TopAppBar";
import Sidebar from "./Sidebar";
import CreateUpdateProjectDialog from "../modals/CreateUpdateProjectDialog";
import UploadCreationVersion from "../modals/CreateCreationOrVersion";
import { THEME } from "../Settings";
import AboutDialog from "./AboutDialog";
import { TenantConfigContext } from "../context/tenantConfigContext";

const introductionOpen = () => {
  // check if the user has seen the introduction before and if not, open the introduction
  return localStorage.getItem('seenIntroduction') !== 'true'
}

export const Layout = () => {
  // Vars
  const tenantConfig = useContext(TenantConfigContext);
  const themeName = THEME
  const theme = themes[themeName];

  // State
  const [openCreateProject, setCreateProject] = useState(false);
  const [droppedFiles, setFiles] = useState([]); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [selectedFile, setSelectFile] = useState(null);
  const [isNavbarActive, setNavbarActive] = useState(false);
  const [openUploadProject, setUploadProject] = useState(false);
  const [openIntroduction, setOpenIntroduction] = useState(introductionOpen());

  const handleCloseModal = () => {
    setCreateProject(false);
  };

  const onDrop = React.useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    if (acceptedFiles.length > 0) {
      setSelectFile(acceptedFiles[0]);
    }
  }, []);

  const handleCloseCreationModal = () => {
    setSelectFile(null);
    setFiles([]);
  };


  const handleCreateCreation = async () => {
    setSelectFile(null);
    setFiles([]);
  };

  const handleNavbarClick = () => {
    setNavbarActive(!isNavbarActive);
  };


  return (
    <>
      <ThemeProvider theme={theme?.theme}>
        {/* MODALS */}
        {tenantConfig?.show_about_dialog === true && <AboutDialog open={openIntroduction} onClose={() => setOpenIntroduction(false)} />}

        <CreateUpdateProjectDialog
          open={openCreateProject}
          onClose={handleCloseModal}
          action={CRUD.Create}
        />
        <UploadCreationVersion
          open={openUploadProject && selectedFile != null}
          onClose={handleCloseCreationModal}
          onSubmit={handleCreateCreation}
          selectedFile={selectedFile}
          projectId={null}
          creationId={null}
        />


        {/* TOP APP BAR */}
        <TopAppBar
          isNavbarActive={isNavbarActive}
          handleNavbarClick={handleNavbarClick}
          theme={theme}
        />
        <Box sx={{ display: { lg: "flex" } }}>
          {/* SIDEBAR */}
          <Sidebar
            isNavbarActive={isNavbarActive}
            setCreateProject={setCreateProject}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              mt: 7,
              backgroundColor: "background.default",
              maxWidth: "2000px"
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};
