import { useEffect, useRef, useState } from 'react'
import WebViewer from '@pdftron/webviewer';
import { Box } from '@mui/material';

const DocViewer = ({ url }) => {
    const viewer = useRef(null);
    const [instance, setInstance] = useState(null);

    const viewerOptions = {
        isReadOnly: true,
        enableAnnotations: false,
        disableLogs: true,
        enableOfficeEditing: false,
        disabledElements: [
            'contextMenuPopup',
            'viewControlsButton',
            'leftPanelButton',
            'menuButton',
            'selectToolButton',
            'panToolButton',
            'searchButton'
        ],
    }

    useEffect(() => {
        WebViewer(
            {
                path: '/webviewer/lib/public',
                licenseKey: process.env.REACT_APP_APRYSE_LICENCE_KEY,
                initialDoc: url,
                ...viewerOptions
            },
            viewer.current,
        ).then((instance) => {
            const docViewer = instance.Core.documentViewer;

            docViewer.addEventListener('documentLoaded', function () {
                // Set Zoom level
                instance.UI.setZoomLevel(1.25);
                // Update styles
                const iframeDoc = instance.UI.iframeWindow.document;
                const app_element = iframeDoc.querySelector<HTMLElement>('.App');
                app_element.style.backgroundColor = "white";
                const header = iframeDoc.querySelector<HTMLElement>('.HeaderItems');
                header.style.backgroundColor = "white";
                const divider_to_hide = iframeDoc.querySelector<HTMLElement>('.divider.hide-in-small-mobile');
                divider_to_hide.style.display = "none";
                const zoom_overlay = iframeDoc.querySelector<HTMLElement>('.zoom-overlay');
                zoom_overlay.style.backgroundColor = "white";

                setInstance(instance);
            });
        });
    }, []);

    // This is a hack to get the document to be displayed properly if the user switches tabs
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && instance) {
                instance.UI.setZoomLevel(instance.UI.getZoomLevel());
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [instance]);

    useEffect(() => {
        if (instance) {
            instance.UI.loadDocument(url, {
                ...viewerOptions
            });
            instance.UI.setZoomLevel("100%");
        }
    }, [url]);


    return (
        <Box ref={viewer}
            sx={{
                width: "100%",
                height: "80vh",
            }}
        />
    );
};

export default DocViewer
