// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import 'moment/locale/fr';
import 'moment/locale/nl';
import moment from "moment";
import { THEME } from './Settings'
import { themes } from "./theme/themes";

const resources = {
  en: {
    translation: {
      "admin": "Admin",
      "dashboard": "Dashboard",
      "create project": "Create Project",
      "upload file": "Upload File",
      "upload date": "Upload Date",
      "my projects": "My Projects",
      "projects": "Projects",
      "starred": "Starred",
      "shared": "Shared",
      "share": "Share",
      "expires": "Expires",
      "expired": "Expired",
      "expired on": "Expired on",
      "expires in": "Expires in",
      "expires on": "Expires on",
      "never expires": "Never expires",
      "revoked": "Revoked",
      "view": "View",
      "viewed": "Viewed",
      "view_details": "View Details",
      "view_count": "Total Views",
      "settings": "Settings",
      "recent creations": "Recent Creations",
      "recent projects": "Recent Projects",
      "project": "Project",
      "creation": "Creation",
      "creations": "Creations",
      "version": "Version",
      "versions": "Versions",
      "versions uploaded": "Versions Uploaded",
      "timestamps": "Timestamps",
      "timestamp": "Timestamp",
      "file": "File",
      "files": "Files",
      "storage": "Storage",
      "users": "Users",
      "shared_link": "Shared Link",
      "shared links": "Shared Links",
      "cumulative": "Cumulative",
      "Click or drag file": "Click or drag to upload a file.",
      "file formats": "Supported file types: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (Max 100MB)",
      "search": "Search",
      "new version": "New Version",
      "version history": "Version History",
      "shared_with": "Shared With",
      "shared_links": "Shared Links",
      "info": "Info",
      "profile": "Profile",
      "logout": "Logout",
      "version not shared": "This version has not been shared yet",
      "file hash": "File Hash",
      "confirm_delete": "Confirm Delete",
      "description": "Description",
      "no_description_available": "No description available",
      "created": "Created",
      "date_created": "Date Created",
      "date_shared": "Date Shared",
      "notes": "Notes",
      "actions": "Actions",
      "download_certificate": "Download Certificate",
      "download_file": "Download File",
      "downloading_certificate": "Downloading Certificate",
      "downloading_file": "Downloading File",
      "resend_confirmation_email": "Resend Confirmation Email",
      "active_links": "Active Links",
      "revoked_expired_links": "Revoked & Expired Links",
      "no_projects_created": "You have not created any projects yet, click \"Create Project\" in the sidebar to get started.",
      "unexpected_error": "An unexpected error occurred. Please try again later.",
      "timestamp_confirmation_email_sent": "Timestamp confirmation email sent",
      "show_all_versions": "Show all versions",
      "type_to_search": "Type to search...",
      "go_back_to": "Go back to",
      // Select Profile
      "select_profile": "Choose a different profile",
      // Page Descriptions
      "app_description": "",
      "creation_version_view_description": "You can upload new versions of your work, view the version history, or share your work (again) with others. The timestamp certificate and your file can be downloaded via 'actions'",
      "my_projects_description": "This page gives an overview of all your projects. A project folder stores the different files that contribute to a musical work, such as your audio files and song texts. This can be per song, or for multiple songs.",
      "project_detail_description": "Each time you upload a file, you'll receive a confirmation email with a (timestamp) certificate. Click on any file name in the list to share the file with others or to view further details of the timestamp.",
      // Buttons
      "create": "Create",
      "save": "Save",
      "cancel": "Cancel",
      "no": "No",
      "yes": "Yes",
      "edit": "Edit",
      "delete": "Delete",
      "close": "Close",
      // Vault Storage Sidebar
      "vault storage": "Vault Storage",
      "usedData": "You have used <strong>{{dataMb}}</strong>",
      "usedDataWithLimit": "You have used <strong>{{dataMb}} ({{dataPercent}}%)</strong> of your available space",
      // Table headers
      "name": "Name",
      "uploaded by": "Uploaded By",
      "first upload date": "First Upload Date",
      "last modified": "Last Modified",
      "last updated": "Last Updated",
      "filename": "Filename",
      "file type": "File Type",
      "file size": "File Size",
      // File Processing
      "file_processing_error": "There was an error when processing this file. Please contact support",
      "file_currently_processing": "This file is currently being processed. Please check back later for Timestamp information",
      // Delete Messages
      "delete_project_message": "Are you sure you want to permanently delete this project? All works and their versions in this project will be permanently deleted.",
      "delete_creation_message": "Are you sure you want to permanently delete this work? All versions of this work will be permanently deleted.",
      "delete_creation_version_message": "Are you sure you want to permanently delete this version of your work?",
      "delete_sharing_link_message": "Are you sure you want to delete this sharing link?",
      // Delete Error Messages
      "delete_project_error": "There was an error when deleting this project. Please try again later",
      "delete_creation_version_error": "There was an error when deleting this version of your work. Please try again later",
      // Project Create Form
      "project_form": {
        "title_create": "Create Project",
        "title_edit": "Update Project",
        "description": "Set up a project folder to store your files",
        "fields": {
          "project_title_label": "Project Title",
          "project_title_helpertext": "The title of the project",
          "project_description_label": "Project Description",
          "project_description_helpertext": "Description of your project (optional)",
        }
      },
      // File/Creation Upload Form
      "upload_creation_form": {
        "title": "Upload Creation",
        "upload_error_already_exists": "You have already uploaded this file before. Please select a different file.",
        "upload_error_file_too_large": "The file you are trying to upload is too large. Please upload a file smaller than 100MB.",
        "upload_error_file_type_not_allowed": "The file type you are trying to upload is not supported. Please upload a file with one of the following types: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "Select the project you would like to upload this creation under. If you do not have a project yet, you can create a new one. If this is a new version of an existing creation, you can also select the creation to upload the new version under.",
        "select_project_helpertext": {
          "default": "Select a Project to upload this file under.",
          "project_selected_no_creations": "This file will be uploaded as a new creation under the project",
          "project_selected": "Select a Creation below to upload as a new version.",
          "creation_selected": "This file will be uploaded as a new version under the creation"
        },
        "headers": {
          "uploaded_file_details": "Uploaded File Details",
          "creation_details": "Creation Details",
          "select_project": "Select Project"
        },
        "fields": {
          "creation_name_label": "Creation Name",
          "creation_name_helpertext": "The title of the newly uploaded creation.",
          "creation_type_label": "Creation Type",
          "creation_type_helpertext": "The type of creation being uploaded",
          "creation_description_label": "Description",
          "creation_description_helpertext": "A brief description about this creation. If you are uploading a new version, you may want to describe the changes made.",
        },
        "buttons": {
          "create_new_project": "Create New Project",
          "upload": "Upload"
        }
      },
      // Share Creation Form
      "share_creation_form": {
        "title": "Share Creation Version: {{ creation_name }}",
        "description": "Share this version of your work with someone else. Through a unique link in the email, your work can be viewed or listened to for the period you specify below.",
        "fields": {
          "email_label": "Email Address",
          "email_helpertext": "Enter the email address of the person you want to share with",
          "duration_label": "Duration",
          "duration_helpertext": " Specify how long the unique link is valid for",
          "notes_label": "Notes",
          "notes_helpertext": "Add a note for the recipient",
        },
      },
      // Shared Links search form
      "shared_links_search_form": {
        "placeholder": "Search by project, creation or recipient email"
      },
      "duration_units": {
        "minutes": "minutes",
        "hour": "hour",
        "hours": "hours",
        "day": "day",
        "days": "days",
        "week": "week",
        "weeks": "weeks",
        "month": "month",
        "months": "months",
        "year": "year",
        "years": "years",
        "all": "all time",
      },
      // Creation Types
      "Recording": "Recording",
      "Lyric": "Lyric",
      "Sheet Music": "Sheet Music",
      "Video": "Video",
      "Picture": "Picture",
      "Agreement": "Agreement",
      "Other": "Other",
      // External Sharing
      "shared_by": "Shared by {{ name }} on {{ date }}",
      "timestamped_by": "Timestamped by {{ name }}",
      "link_expires_on": "This link expires on {{ date }}",
      "link_expired": "This link has expired or the file permissions have been modified.",
      // ABOUT PAGE
      "about": {
        "BUMASTEMRA": `In MyMusicSafe, you can store your music or lyrics in a secure environment and share them with others. You determine how long others have access to your file(s). They can only view or listen to the files within the secure environment.
Both concepts and final versions of works/files can be stored in organized project folders. Each time you upload a file, you will receive a confirmation with a timestamp indicating that you registered this work at a specific moment.
This confirmation will be sent via email and is available for download as a PDF with all relevant metadata.
Is your work ready to be submitted to BumaStemra? Then go to the 'Works' page and select 'Submit New Work' to register your work.`
      },
    },
  },
  fr: {
    translation: {
      "admin": "Admin",
      "dashboard": "Dashboard",
      "create project": "Créer un projet",
      "upload file": "Téléverser un fichier",
      "upload date": "Date de dépôt",
      "my projects": "Mes projets",
      "starred": "Favoris",
      "shared": "Partagé",
      "share": "Partager",
      "settings": "Paramètres",
      "recent creations": "Créations récentes",
      "recent projects": "Projets récents",
      "Click or drag file": "Cliquez ou glissez un fichier dans cette zone pour le téléverser",
      "file formats": "Types de fichiers supportés : .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt",
      "search": "Rechercher",
      "new version": "Nouvelle version",
      "version history": "Historique des versions",
      "shared with": "Partagé avec",
      "info": "Infos",
      "profile": "Profil",
      "logout": "Déconnexion",
      "version not shared": "Cette version n'a pas encore été partagée",
      "file hash": "Hash de fichier",
      // Vault Storage Sidebar
      "vault storage": "Vault Stockage",
      "you have used": "Vous avez utilisé",
      "of your available space": "de votre espace disponible",
      // Table headers
      "name": "Nom",
      "uploaded by": "Telechargé Par",
      "first upload date": "Date du premier téléchargement",
      "last modified": "Dernière modification",
      "filename": "Nom de fichier",
      "file type": "Type de fichier",
      "version": "Version",
      //File Processing
      "file_processing_error": "Une erreur s'est produite lors du traitement de ce fichier. Veuillez contacter l'assistance",
      "file_currently_processing": "Ce dossier est actuellement en cours de traitement. Veuillez revenir plus tard pour obtenir des informations sur l'horodatage."

    },
  },
  nl: {
    translation: {
      "admin": "Admin",
      "dashboard": "Dashboard",
      "create project": "Project aanmaken",
      "upload file": "Bestand uploaden",
      "upload date": "Upload datum",
      "my projects": "Mijn projecten",
      "projects": "Projecten",
      "starred": "Favorieten",
      "shared": "Gedeeld",
      "share": "Delen",
      "view": "Bekijk",
      "viewed": "Bekeken",
      "view_details": "Bekijk details",
      "view_count": "Totaal bekeken",
      "expires": "Verloopt",
      "expired": "Verlopen",
      "expired on": "Verlopen op",
      "expires in": "Verloopt in",
      "expires on": "Verloopt op",
      "never expires": "Verloopt nooit",
      "revoked": "Ingetrokken",
      "settings": "Instellingen",
      "recent creations": "Recente creaties",
      "recent projects": "Recente projecten",
      "project": "Project",
      "creation": "Creatie",
      "creations": "Creaties",
      "version": "Versie",
      "versions": "Versies",
      "versions uploaded": "Versies geüpload",
      "timestamps": "Datumstempels",
      "timestamp": "Datumstempel",
      "file": "Bestand",
      "files": "Bestanden",
      "storage": "Opslag",
      "users": "Gebruikers",
      "shared links": "Gedeelde links",
      "cumulative": "Cumulatief",
      "Click or drag file": "Sleep hier je bestand naartoe of klik om te uploaden.",
      "file formats": "Ondersteunde bestandstypen: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov (Maximaal 100MB)",
      "search": "Zoeken",
      "new version": "Nieuwe versie",
      "version history": "Versiegeschiedenis",
      "shared_with": "Gedeeld met",
      "shared_link": "Gedeelde link",
      "shared_links": "Gedeelde links",
      "info": "Info",
      "profile": "Profiel",
      "logout": "Uitloggen",
      "version not shared": "Deze versie is nog niet gedeeld",
      "file hash": "Bestand hash",
      "confirm_delete": "Bevestig verwijderen",
      "description": "Beschrijving",
      "no_description_available": "Geen beschrijving beschikbaar",
      "created": "Aangemaakt",
      "date_created": "Datum aangemaakt",
      "date_shared": "Datum gedeeld",
      "notes": "Notities",
      "actions": "Acties",
      "download_certificate": "Download certificaat",
      "download_file": "Download bestand",
      "downloading_certificate": "Certificaat downloaden",
      "downloading_file": "Bestand downloaden",
      "resend_confirmation_email": "Bevestigingsmail opnieuw versturen",
      "active_links": "Actieve links",
      "revoked_expired_links": "Ingetrokken & verlopen links",
      "no_projects_created": "Je hebt nog geen projecten aangemaakt, klik op \"Project aanmaken\" in de zijbalk om te beginnen.",
      "unexpected_error": "Er is een onverwachte fout opgetreden. Probeer het later opnieuw.",
      "timestamp_confirmation_email_sent": "Timestamp bevestigingsmail verstuurd",
      "show_all_versions": "Toon alle versies",
      "type_to_search": "Typ om te zoeken...",
      "go_back_to": "Ga terug naar",
      // Select Profile
      "select_profile": "Kies hier een ander profiel",
      // Page Descriptions
      "app_description": "",
      "creation_version_view_description": "Je kunt nieuwe versies van je werk uploaden, de versiegeschiedenis bekijken of je werk (nogmaals) delen met anderen. Het (datumstempel)certificaat en je bestand zijn opnieuw te downloaden via 'acties'.",
      "my_projects_description": "Deze pagina geeft een overzicht van al je projecten. In een projectmap sla je de verschillende bestanden op die te maken hebben met een muziekwerk, zoals je audiobestanden en liedteksten. Dit kan per liedje zijn, of voor meerdere liedjes.",
      "project_detail_description": "Elke keer dat je een bestand uploadt, ontvang je een bevestigingsmail met het (datumstempel)certificaat. Klik op een bestandsnaam in de lijst om het bestand te delen met anderen, of om verdere details van de datumstempel te bekijken.",
      // Buttons
      "create": "Aanmaken",
      "save": "Opslaan",
      "cancel": "Annuleren",
      "no": "Nee",
      "yes": "Ja",
      "edit": "Bewerken",
      "delete": "Verwijderen",
      "close": "Sluiten",
      // Vault Storage Sidebar
      "vault storage": "Kluis opslag",
      "usedData": "Je hebt <strong>{{dataMb}}</strong> gebruikt",
      "usedDataWithLimit": "Je hebt <strong>{{dataMb}} ({{dataPercent}})</strong> van je beschikbare ruimte gebruikt",
      // Table headers
      "name": "Naam",
      "uploaded by": "Geupload door",
      "first upload date": "Datum eerste upload",
      "last modified": "Laatst gewijzigd",
      "last updated": "Laatst bijgewerkt",
      "filename": "Bestandsnaam",
      "file type": "Bestandstype",
      "file size": "Bestandsgrootte",
      //File Processing
      "file_processing_error": "Er is een fout opgetreden bij het verwerken van dit bestand. Neem contact op met de ondersteuning",
      "file_currently_processing": "Dit bestand wordt momenteel verwerkt. Kom later terug voor datumstempelinformatie",
      // Delete Messages
      "delete_project_message": "Weet je zeker dat je dit project definitief wil verwijderen? Alle werken en hun versies in dit project zullen definitief worden verwijderd.",
      "delete_creation_message": "Weet je zeker dat je dit werk definitief wil verwijderen? Alle versies van dit werk worden definitief verwijderd.",
      "delete_creation_version_message": "Weet je zeker dat je deze versie van je werk definitief wil verwijderen?",
      "delete_sharing_link_message": "Weet je zeker dat je deze deellink wil verwijderen?",
      // Delete Error Messages
      "delete_project_error": "Er is een fout opgetreden bij het verwijderen van dit project. Probeer het later opnieuw",
      "delete_creation_version_error": "Er is een fout opgetreden bij het verwijderen van deze versie van je werk. Probeer het later opnieuw",
      // Project Create Form
      "project_form": {
        "title_create": "Project aanmaken",
        "title_edit": "Project bijwerken",
        "description": "Creëer een projectmap voor het opslaan van je bestanden",
        "fields": {
          "project_title_label": "Project titel",
          "project_title_helpertext": "Geef je project een titel",
          "project_description_label": "Toelichting project",
          "project_description_helpertext": "Omschrijving van je project (optioneel)",
        }
      },
      // File/Creation Upload Form
      "upload_creation_form": {
        "title": "Bestand uploaden",
        "upload_error_already_exists": "Je hebt dit bestand al eerder geüpload. Selecteer een ander bestand.",
        "upload_error_file_too_large": "Het bestand dat je probeert te uploaden is te groot. Upload een bestand kleiner dan 100MB.",
        "upload_error_file_type_not_allowed": "Het bestandstype dat je probeert te uploaden wordt niet ondersteund. Upload een bestand met een van de volgende typen: .mp3, .m4a, .aac, .flac, .wav, .ogg, .pdf, .docx, .txt, .jpg/jpeg, .png, .mp4, .mov",
        "select_project_description": "Selecteer het project waaronder je deze creatie wilt uploaden. Als je nog geen project hebt, kun je een nieuw project aanmaken. Als dit een nieuwe versie is van een bestaande creatie, kun je ook de creatie selecteren om de nieuwe versie onder te uploaden.",
        "select_project_helpertext": {
          "default": "Selecteer een Project om dit bestand onder te uploaden.",
          "project_selected_no_creations": "Dit bestand wordt geüpload als een nieuwe creatie onder het project",
          "project_selected": "Selecteer hieronder een Creatie om als een nieuwe versie te uploaden.",
          "creation_selected": "Dit bestand wordt geüpload als een nieuwe versie onder de creatie"
        },
        "headers": {
          "uploaded_file_details": "Details bestand",
          "creation_details": "Creatie details",
          "select_project": "Selecteer project"
        },
        "fields": {
          "creation_name_label": "Titel",
          "creation_name_helpertext": "Kies een titel of pas deze aan",
          "creation_type_label": "Type",
          "creation_type_helpertext": "Het type bestand dat wordt geupload",
          "creation_description_label": "Toelichting",
          "creation_description_helpertext": "Omschrijving van je werk of nieuwe versie (optioneel)",
        },
        "buttons": {
          "create_new_project": "Nieuw project aanmaken",
          "upload": "Uploaden"
        }
      },
      // Share Creation Form
      "share_creation_form": {
        "title": "Deel je werk: {{ creation_name }}",
        "description": "Deel deze versie van je werk met iemand anders. Door middel van een unieke link in de e-mail kan je werk worden bekeken of beluisterd gedurende de periode die je hieronder aangeeft.",
        "fields": {
          "email_label": "e-mailadres",
          "email_helpertext": "Voer het e-mailadres in van de persoon met wie je wilt delen",
          "duration_label": "Periode",
          "duration_helpertext": "Bepaal hoe lang de unieke link geldig is",
          "notes_label": "Notities",
          "notes_helpertext": "Voeg een notitie toe voor de ontvanger",
        },
      },
      // Shared Links search form
      "shared_links_search_form": {
        "placeholder": "Zoeken op project, creatie of ontvanger e-mail"
      },
      "duration_units": {
        "minutes": "minuten",
        "hour": "uur",
        "hours": "uren",
        "day": "dag",
        "days": "dagen",
        "week": "week",
        "weeks": "weken",
        "month": "maand",
        "months": "maanden",
        "year": "jaar",
        "years": "jaren",
        "all": "altijd",
      },
      // Creation Types
      "Recording": "Opname",
      "Lyric": "Tekst",
      "Sheet Music": "Bladmuziek",
      "Video": "Video",
      "Picture": "Afbeelding",
      "Agreement": "Overeenkomst",
      "Other": "Anders",
      // External Sharing
      "shared_by": "Gedeeld door {{ name }} op {{ date }}",
      "timestamped_by": "Timestamped door {{ name }}",
      "link_expires_on": "Deze link verloopt op {{ date }}",
      "link_expired": "Deze link is verlopen of de bestandsrechten zijn gewijzigd.",
      // ABOUT PAGE
      "about": {
        "BUMASTEMRA": `In MyMusicSafe kan je jouw muziek of teksten in een beveiligde omgeving opslaan en delen met anderen. Jij bepaalt hoe lang anderen toegang hebben tot je bestand(en). Zij kunnen de bestanden alleen bekijken of beluisteren binnen de beveiligde omgeving.
    Zowel concepten als definitieve versies van werken/bestanden kunnen worden opgeslagen in overzichtelijke projectmappen. Elke keer dat je een bestand uploadt, ontvang je een bevestiging met een datumstempel die aangeeft dat jij dit werk op een specifiek moment hebt geregistreerd.
    Deze bevestiging wordt per e-mail verzonden en is beschikbaar om te downloaden als PDF met alle relevante metadata. 
    Is je werk klaar en wil je dit aanmelden? Ga dan naar de 'Werken'-pagina en kies voor 'Nieuw werk aanmelden' om je werk aan te melden.`
      },
    }
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('lng') || themes[THEME].defaultLanguage,
    fallbackLng: themes[THEME].defaultLanguage,
    interpolation: {
      escapeValue: false,
    },
  });

// Set Moment.js locale
moment.locale(i18n.language);

export default i18n;
