import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react'
import i18n from '../i18n';
import moment from 'moment';
import { LANGUAGES } from '../Constants';
import Flag from "react-world-flags";
import { updateUser } from '../api/user/updateUser';
import { useMutation } from '@tanstack/react-query';

const LanguageSelect = (props: any) => {

    const { mutate } = useMutation({
        mutationFn: updateUser,
        onSuccess: (data) => {
            i18n.changeLanguage(data.language);
            localStorage.setItem('lng', data.language);
            moment.locale(i18n.language);
        },
        onError: (error) => {
            console.log("Error updating language", error)
        }
    });

    const onChangeLang = async (e: SelectChangeEvent<string>) => {
        mutate({ language: e.target.value });
    };

    const style = props?.small ? { m: 0, p: 0 } : {};
    return (
        <MenuItem sx={style}>
            <FormControl sx={{ m: 0, border: 0, width: props?.width || "100%" }} size="small">
                <Select
                    defaultValue={i18n.language}
                    onChange={onChangeLang}
                    value={i18n.language}
                >
                    {LANGUAGES.map(({ label, code, flag }) => {
                        if (props?.shorthand) {
                            label = code.toUpperCase()
                        }

                        return (
                            <MenuItem key={code} value={code} sx={{ width: props?.width || "100%" }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Flag code={flag} height={20} width={20} /> {label}
                                </Box>
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </MenuItem>
    )
}

export default LanguageSelect
